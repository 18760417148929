const defaultConfig = {
    http: {
        url: process.env.VUE_APP_BACKEND_URL,
        defaultRequest: {
            headers: {
                'Content-Type': 'application/json',
                'Application-Token': process.env.VUE_APP_BACKEND_TOKEN,
                'Accept': 'application/json',
            }
        }
    }
}

export default defaultConfig;