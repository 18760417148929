<script>
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    }
  },
  mounted() {
    document.title = this.title;
  },
  watch: {
    $route (from){
      document.title = this.title;
    },
  }
}
</script>

<template>
<div id="app">
  <transition name="slide-fade" mode="out-in">
    <router-view />
  </transition>
</div>
</template>

<style lang="scss">
.slide-fade-enter-active {
  animation: slide-fade-in 0.5s ease;
}
@keyframes slide-fade-in {
  from {
    transform: translateX(10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

</style>
